import { usePathname, useSearchParams } from 'next/navigation';

export function useActiveSidebarLink(href: string) {
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const creatorId = searchParams.get('id');
  const currentUrl = `${pathname}${creatorId ? `?id=${creatorId}` : ''}`;
  const isActive = href ? href.replaceAll('/', '') === currentUrl.replaceAll('/', '') : false;

  return isActive;
}
