import { useRouter } from 'next/navigation';
import posthog from 'posthog-js';

import { userApi } from '@lib/redux/api/user-api';
import { clearUser } from '@lib/redux/features/user';
import { cookieService } from '@lib/cookie.service';
import { useAppDispatch } from '@lib/redux/store';
import { ROUTES } from '@constants/routes.constants';
import { badgesApi } from '@lib/redux/api/badges-api';
import { messagesApi } from '@lib/redux/api/messages-api';
import { claimApi } from '@lib/redux/api/claim-api';
import { leaderboardApi } from '@lib/redux/api/leaderboard-api';
import { creatorsApi } from '@lib/redux/api/creators-api';

export function useLogout() {
  const router = useRouter();
  const dispatch = useAppDispatch();

  function onLogout() {
    cookieService.clearAuthCookies();

    dispatch(clearUser());
    dispatch(badgesApi.util.resetApiState());
    dispatch(claimApi.util.resetApiState());
    dispatch(creatorsApi.util.resetApiState());
    dispatch(leaderboardApi.util.resetApiState());
    dispatch(messagesApi.util.resetApiState());
    dispatch(userApi.util.resetApiState());
    posthog.reset();

    router.push(ROUTES.auth);
  }

  return onLogout;
}
