import Link from 'next/link';
import { twMerge } from 'tailwind-merge';

interface ISidebarLink {
  isActive?: boolean;
  className?: string;
  onClick?(): void;
  href?: string;
}

export function SidebarLink({
  children,
  isActive,
  className,
  href,
  onClick,
}: React.PropsWithChildren<ISidebarLink>) {
  const params = {
    className: twMerge(
      isActive
        ? 'bg-fanOrangeActive cursor-default'
        : 'group cursor-pointer hover:bg-fanGreyHover active:bg-fanOrangeActive',
      className,
    ),
    children,
  };

  return href ? <Link {...params} href={href} /> : <div {...params} onClick={onClick} />;
}
