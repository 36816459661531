import { Avatar, Icon, Note, Title } from '@ui/components';
import { SidebarLink } from './sidebar-link';
import { ROUTES } from '@constants/routes.constants';
import { useActiveSidebarLink } from './use-active-sidebar-link';
import { twMerge } from 'tailwind-merge';

export interface ISidebarUser {
  avatar: string;
  name: string;
  username: string;
  id: string;
  isLoading: boolean;
}

export function SidebarUser({ avatar, name, username, isLoading }: ISidebarUser) {
  const href = ROUTES.settings;
  const isActive = useActiveSidebarLink(href);
  return (
    <SidebarLink
      href={href}
      isActive={isActive}
      className="group mx-6 flex flex-col items-center rounded-xl px-2 py-4">
      <div className="border-subtitle mb-4 rounded-full border-4">
        <Avatar src={avatar} className="border-0" isLoading={isLoading} />
      </div>
      <div className="mb-2.5 flex items-center">
        <Title type="s" className="font-bold text-white">
          {!username ? 'Loading...' : `@${username}`}
        </Title>
        <Icon
          type="back"
          stroke=""
          className={twMerge(
            'group-active:[&>path]:stroke-primary inline rotate-180',
            isActive ? '[&>path]:stroke-primary' : '[&>path]:stroke-note',
          )}
        />
      </div>
      <Note type="s" className={`group-active:text-primary ${isActive ? 'text-primary' : ''}`}>
        {isLoading ? 'Loading...' : name}
      </Note>
    </SidebarLink>
  );
}
