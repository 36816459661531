import { Icon, Text } from '@ui/components';
import { SidebarLink } from './sidebar-link';

interface ISidebarLogout {
  onLogout(): void;
}

export function SidebarLogout({ onLogout }: ISidebarLogout) {
  return (
    <div className="h-28 pb-4">
      <div className="border-korDarkGrey mx-10 my-3 border-b" />
      <SidebarLink onClick={onLogout} className="mx-4 rounded-xl">
        <div className="flex items-center px-4 py-4">
          <Icon type="logout" stroke="#fff" className="mr-2" />
          <Text type="l" className="text-white">
            Exit
          </Text>
        </div>
      </SidebarLink>
    </div>
  );
}
