import { Note, Text } from '@ui/components';
import { SidebarLink } from './sidebar-link';

import { FanIcon } from '@components/fan-icon';
import { twMerge } from 'tailwind-merge';
import { ROUTES } from '@constants/routes.constants';
import { useActiveSidebarLink } from './use-active-sidebar-link';

export interface SidebarAllCreators {
  totalBadges: number;
}

export function SidebarAllCreators({ totalBadges }: SidebarAllCreators) {
  const href = ROUTES.creators;
  const isActive = useActiveSidebarLink(href);
  return (
    <SidebarLink href={href} isActive={isActive} className="flex rounded-full p-2">
      <div
        className={twMerge(
          'bg-fanOrangeActive mr-2 flex aspect-square h-12 items-center justify-center rounded-full',
          isActive ? 'bg-primary' : '',
        )}>
        <FanIcon type="ticket" stroke={isActive ? '#fff' : ''} />
      </div>
      <div className="flex flex-col">
        <Text type="l" className="text-white">
          All Creators
        </Text>
        <Note type="s" className={twMerge('group-active:text-primary', isActive && 'text-primary')}>
          Total: {totalBadges} Badge{totalBadges > 1 && 's'}
        </Note>
      </div>
    </SidebarLink>
  );
}
